/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: 'Oxygen';
  vertical-align: baseline;

}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
  background-image: url("./assets/flowers.jpg");
  background-attachment: fixed;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* begin homemade css */

a:link, a:visited {
  color: midnightblue;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(30px);
}

.hero {
  font-size: 45px;
  margin-bottom: 67px;
  animation: enter-text;
  animation-duration: 2s;
  background-color: black;
  color: floralwhite;
  width: 100vw;
  max-width: 100%;
  text-align: center;
  height: 56px;
  font-style: italic;
  padding: 10px 0;
}

.intro-title {
  font-size: 25px;
  position: absolute;
  top: 85px;
  text-align: center;
  /*
  animation-name: introh2, enter-introh2;
  animation-duration: introh2 4s, enter-introh2 2s;
  animation-iteration-count: introh2 infinite, enter-introh2 1;
  animation-timing-function: ease;*/
  animation: introh2 4s infinite ease, enter-introh2 2s 1 ease;
}

.bio {
  line-height: 20px;
  max-width: 700px;
  font-size: 16px;
  padding: 20px;
  animation-name: enter-bio;
  animation-duration: 2s;
}

.links {
  display: flex;
  justify-content: space-evenly;
  backdrop-filter: blur(20px) grayscale(100%);
  padding: 7px;
  margin-top: -3px;
}

.links a:hover {
  filter: invert();
}

.links img {
  filter: brightness(0) invert(1);
}

.contact-logo {
  width: 50px;
}

.intro-black {
  animation: intro-black 4s 1 ease;
}

.project-list {
  display: flex;
  flex-direction: column;
  align-items: top;
  max-width: 900px;
}

.section-title {
  width: 100vw;
  max-width: 100%;
  font-size: 30px;
  padding: 19px 0;
  text-align: center;
  text-shadow: 2px 3px gainsboro;
}

.project {
  max-width: 900px;
  border-top: none;
  /*box-shadow: 9px 12px 30px;*/
}

/*
.ytpoet:hover {
  animation: card-hover1;
  animation-duration: 2s;
}

.bread_bot:hover {
  animation: card-hover2;
  animation-duration: 2s;
}

.chord-magic:hover{
  animation: card-hover3;
  animation-duration: 2s;
}

.bottom-feeder:hover {
  animation: card-hover4;
  animation-duration: 2s;
}*/

.project h3 {
  text-align: center;
  font-size: 20px;
  padding: 10px;
  backdrop-filter: blur(30px) saturate(300%);
}

.project-links {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  backdrop-filter: blur(30px);
}

.project-image {
  max-width: 500px;
  margin-top: -3px;
  position: relative;
  z-index: 1;
}

.project-description {
  padding: 15px;
  backdrop-filter: blur(30px);
  margin-top: -3px;
}

.tech-used {
  backdrop-filter: blur(30px);
}

.tech-list {
  padding: 10px;
}

.tech-icon {
  width: 24px;
  margin-right: 8px;
  float: left;
}

.music {
  margin-top: 20px;
}

.refractor {
  margin-bottom: 45px;
}

.end-space {
  display: none;
}

html {
  scroll-behavior: smooth;
}

.project-anchor-links {
  display: none;
}

@keyframes card-hover1 {
  0% {margin-left: -5px;}
  25% {margin-top: 5px; margin-left: 0px;}
  50% {margin-right: 5px; margin-top: 0px;}
  75% {margin-bottom: -5px; margin-right: 0px;}
}

@keyframes card-hover2 {
  0% {margin-left: -5px;}
  25% {margin-top: 5px; margin-left: 0px;}
  50% {margin-right: 5px; margin-top: 0px;}
  75% {margin-bottom: -5px; margin-right: 0px;}
}

@keyframes card-hover3 {
  0% {margin-left: -5px;}
  25% {margin-top: 5px; margin-left: 0px;}
  50% {margin-right: 5px; margin-top: 0px;}
  75% {margin-bottom: -5px; margin-right: 0px;}
}

@keyframes card-hover4 {
  0% {margin-left: -5px;}
  25% {margin-top: 5px; margin-left: 0px;}
  50% {margin-right: 5px; margin-top: 0px;}
  75% {margin-bottom: -5px; margin-right: 0px;}
}

@keyframes introh2 {
  0% {top: 85px;}
  50% {top: 100px;}
}

@keyframes enter-introh2 {
  from {left: -2500px;}
  to {left: 15%;}
}

@keyframes enter-text {
  from {margin-top: -1000px;}
  to {margin-top: initial; }
}

@keyframes enter-bio {
  from {margin-left: 5000px;}
  to {margin-right: initial;}
}

@keyframes intro-black {
  from {height: 2000px;}
  to {height: 0px;}
}

@media screen and (max-width: 540px) {
  .project-image {
    width: 100vw;
    max-width: 100%;
  }

  .project {
    margin-bottom: 15px;
    border: 1px solid black;
  }

  .tech-used, .project-links, .links {
    margin-top: -3px;
  }
}

@media screen and (min-width: 520px) {
  @media screen and (max-width: 800px) {
    .chord-magic .project-image {
      max-width: 515px;
    }
  
    .chord-magic .project-description {
      padding-bottom: 0;
      line-height: 20px;
    }


    .image-left {
      float: left;
      margin-right: 25px;
    }
    
    .image-right {
      float: right;
      margin-left: 25px;
    }

    .image-left {
      border-radius: 0 0 0 10px;
    }

    .image-right {
      border-radius: 0 0 10px 0;
    }
  }

  .grandfather .project-image {
    width: 230px;
    border-radius: 0 0 10px 0; 
  }

  .refractor .project-image {
    width: 194px;
  }


  .project {
    border: 1px solid black;
    border-radius: 10px;
    margin-bottom: 30px;
    backdrop-filter: blur(30px);
    margin-left: auto;
    margin-right: auto;
  }

  .project h3 {
    border-radius: 10px 10px 0 0;
  }



  .project-links {
    position: relative;
  }

  .ytpoet .project-description {
    line-height: 27px;
  }

  .bottom-feeder .project-image {
    width: 400px;
  }

  @keyframes enter-introh2 {
    from {left: -2500px;}
    to {left: 40%;}
  }
}

@media screen and (min-width: 800px) {
  .bread_bot {
    margin-left: 35vw !important;
  }

  .bottom-feeder {
    margin-right: 40vw !important;
  }

  .grandfather .project-image, .refractor .project-image{
    width: 300px;
  }

  .grandfather, .refractor {
    max-width: 300px;
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .music {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .project-list {
    overflow: auto;
    white-space: nowrap;
    max-width: 100%;
    flex-direction: row;
    scroll-snap-type: x mandatory;
    scroll-snap-points-x: repeat(300px);
  }

  .project {
    display: inline-block;
    margin-left: 150px;
    margin-right: 150px;
    height: 100%;
    scroll-snap-align: center;
  }

  .project-description, .tech-used {
    white-space: normal;
  }

  .ytpoet .tech-list {
    margin-bottom: 15px;
  }

  .end-space {
    display: inline-block;
    visibility: hidden;
  }

  .project-anchor-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }

  .project-anchor-links a {
    text-decoration: none;
    text-shadow: 1px 1px pink;
    font-weight: bold;
    color: darkslategray;
  }

  .project-anchor-links a:hover {
    text-shadow: 1px 1px gray;
    color: pink;
  }

  .project-anchor-links a:active {
    color: lavender;
  }
}